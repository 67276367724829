@import url('https://fonts.googleapis.com/css2?family=IM+Fell+Great+Primer&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Days+One&display=swap');

@keyframes fadeIn {
  0% {
      opacity: 0;
      
    }
   
    100% {
      opacity: 1;
      
    }
}

@font-face {
  font-family: "Lazydog";
  src: url("./fonts/lazydog.otf");
}

* {
  font-family: 'Lazydog', sans-serif;
  color: white;
}

p {
  color: white;
  opacity: .8;
}


.polaroids {
  margin: 0;
}



/* By default, we tilt all our images -2 degrees */
.one {
  -webkit-transform: rotate(-2deg);
  -moz-transform: rotate(-2deg);
  }
   

  .two {
    -webkit-transform: rotate(2deg);
    -moz-transform: rotate(2deg);
    }

    .three {
      -webkit-transform: rotate(6deg);
      -moz-transform: rotate(6deg);
      }

      .four {
        -webkit-transform: rotate(-4deg);
        -moz-transform: rotate(-4deg);
        }

        .five {
          -webkit-transform: rotate(0deg);
          -moz-transform: rotate(0deg);
          }

          .six {
            -webkit-transform: rotate(10deg);
            -moz-transform: rotate(10deg);
            }

            .six {
              -webkit-transform: rotate(-8deg);
              -moz-transform: rotate(-8deg);
              }

              .seven {
                -webkit-transform: rotate(5deg);
                -moz-transform: rotate(5deg);
                }
     
  
  /* Don't rotate every third image, but offset its position */
  ul.polaroids li:nth-child(3n) a {
  -webkit-transform: none;
  -moz-transform: none;
  position: relative;
  top: -5px;
  }
   
  /* Rotate every fifth image by 5 degrees and offset it */
  ul.polaroids li:nth-child(5n) a {
  -webkit-transform: rotate(5deg);
  -moz-transform: rotate(5deg);
  position: relative;
  right: 5px;
  }
   
  /* Keep default rotate for every eighth, but offset it */
  ul.polaroids li:nth-child(8n) a {
  position: relative;
  top: 8px;
  right: 5px;
  }
   
  /* Keep default rotate for every eleventh, but offset it */
  ul.polaroids li:nth-child(11n) a {
  position: relative;
  top: 3px;
  left: -5px;
  }

  ul {
    margin: 0; /* To remove default bottom margin */ 
    padding: 0;
  }

  ul.polaroids a {
    background: #fff;
    display: inline;
    float: left;
    margin: 0 0 27px 0px;
    width: auto;
    padding: 20px 20px 50px;
    text-align: center;
    font-family: "Marker Felt", sans-serif;
    text-decoration: none;
    color: #333;
    font-size: 18px;
    -webkit-box-shadow: 0 3px 6px rgb(0 0 0 / 25%);
    -moz-box-shadow: 0 3px 6px rgba(0,0,0,.25);
    /*-webkit-transform: rotate(13deg);
    
    -moz-transform: rotate(-2deg);*/
}

#submit {
  background-color: transparent;
}

body {
background-size: cover;
background-repeat: no-repeat;
background-attachment: fixed;

background-image: linear-gradient(180deg, rgba(63,27,91,1) 0%, rgba(78,24,152,1) 50%, rgb(95, 19, 201) 100%)
}

.full-center {
  width: 100%;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.under-center {
  width: 100%;
  margin: 0;
  position: absolute;
  top: 80%;
  left: 50%;
  transform: translate(-50%, -0%);
}

.navigationWrapper {

}

ul.polaroids a:after {
  font-family: 'Lazydog', sans-serif;
  opacity: .8;
  content: attr(title);
}

.pageContainer {
  width: 100%;
  min-width: 100%;
  
  padding: 0;
  margin: 0;
  margin: 0 auto;
  min-height: 100vh;
  height: auto;
  -webkit-animation: 3s ease 0s normal forwards 1 fadeIn;
    animation: 3s ease 0s normal forwards 1 fadeIn;
    opacity: 0;
  
}

.container {
  display: flex;
  flex-wrap: wrap;
margin: 0 auto;
  justify-content: space-between;
  align-items: stretch;
  width: 100%;
  flex-direction: row;
}
ul {
  list-style-type: none;
}

li {
  list-style-type: none;
}

.item {
  width: 28%;
  margin: 0 auto !important;

}







h1 {
  font-size: 3em;
  margin: 0px;
}

p {
  font-size: 1.5em;
  margin: 0px;
}

h2 {
  font-size: 1em;
  margin: 0px;
}

.specialDiv {
  width: 50%;
}

.oneLine {
  font-size: 1.2em;
}

.arrow {
  width: 5%;
}

.rest-of-content {
  width: 100%;
  margin: 0;
  position: absolute;
  top: 105%;
  left: 50%;
  transform: translate(-50%, 0%);
}

.twitterFeed {
  width: 30%;
}

.click {
  width: 25%;
  z-index: -1;
  
}

h3 {
  font-size: 1.25em;
  text-align: left;
}

h4 {
  font-size: 1em;
  text-align: left;

}


.memsDoingThings {
  width: 100%;
  position: relative;
  /* left: 50%; */
  /*transform: translate(-50%, 0%);*/
  z-index: 999;
  pointer-events: none;
}

.footerLogo {
  width: 100%;
  /*position: absolute;
  transform: translate(-50%, 180%);
  z-index: -1;*/
}


.customPlayer {
  margin-top: 2em;
  margin-bottom: 2em;
  width: 40%;
  background-color: transparent;
  z-index: -1;
}

.customPlayerButton {
  width: 25%;
}

.navigationContainer {
  margin-top: 1.5em;
  width: 45%;
  display: flex;
}

.navigationheader {
  width: 30%;
}

.block {
  margin: 0;
  width: 33%;


}
.logo {
  width: 30%;
  opacity: .8 !important;
}

.header {
  width: 20%;
}

.submitBtn {
  border: 'none';
  outline: 0;
  border-style: none; outline-style: none;
  width: 10%;
}

.ar {
  width: 15%;

}

.virtual {
  width: 20%;
}



.inputForm {
  width: 15%;
  height: 2em;
}

.textTo {
  font-size: 2.3em;
}

.number {
  font-size: 2.3em;
}

@media only screen and (min-width: 700px) and (max-width: 1000px)
{
   .textTo {
    font-size: 2em;
  }
  
  .number {
    font-size: 2em;
  }

  .item {
    width: 40%;
  }
  .logo {
    width: 40%;
  }

  .header {
    width: 30%;
  }

  .ar {
    width: 40%;
  }

  .virtual {
    width: 50%;
  }
  .inputForm {
    width: 40%;

  }
  

  .submitBtn {
    width: 25%;
  }



  .navigationContainer {
    width: 100%;
    display: inline-block;
  }

  .navigationHeader {
    width: 33%;
  }

  .customPlayer {
    margin-top: 2em;
    margin-bottom: 2em;
    width: 80%;
    background-color: transparent;
  }
  
  .customPlayerButton {
    width: 18%;
  }
  

  .specialDiv {
    width: 100%;
  }

  .oneLine {
    font-size: 1em;
  }
  

  .pageContainer {
    width: 100%;
    padding: 0;
    margin: 0;
    max-width: 100%;
    margin: 0 auto;
    min-height: 100vh;
    height: auto;
    -webkit-animation: 3s ease 0s normal forwards 1 fadeIn;
      animation: 3s ease 0s normal forwards 1 fadeIn;
    
  }
  
  
  
  h1 {
    font-size: 2em;
  }
 
  p {
    font-size: 1.25em;
  }

  h2 {
    font-size: 1em;
  }

  .arrow {
    width: 10%;
  }

  .click {
    width: 30%;
  }

  .under-center {
    width: 100%;
    margin: 0;
    position: absolute;
    top: 75%;
    left: 50%;
    transform: translate(-50%, 0%);
    z-index: 999;
  }

  .rest-of-content {
    width: 100%;
    margin: 0;
    position: absolute;
    top: 105%;
    left: 50%;
    transform: translate(-50%, 0%);
  }

  .twitterFeed {
    width: 70%;
  }

  

  

}

@media only screen and (min-width: 0px) and (max-width: 700px)
{

  .textTo {
    font-size: 2em;
  }
  
  .number {
    font-size: 2em;
  }

  .item {
    width: 90%;
  }
  .logo {
    width: 80%;
  }

  .header {
    width: 40%;
  }

  .ar {
    width: 40%;
  }

  .virtual {
    width: 50%;
  }
  .inputForm {
    width: 40%;

  }
  

  .submitBtn {
    width: 25%;
  }



  .navigationContainer {
    width: 100%;
    display: inline-block;
  }

  .navigationHeader {
    width: 33%;
  }

  .customPlayer {
    margin-top: 2em;
    margin-bottom: 2em;
    width: 80%;
    background-color: transparent;
  }
  
  .customPlayerButton {
    width: 18%;
  }
  

  .specialDiv {
    width: 100%;
  }

  .oneLine {
    font-size: 1em;
  }
  

  .pageContainer {
    width: 100%;
    padding: 0;
    margin: 0;
    max-width: 100%;
    margin: 0 auto;
    min-height: 100vh;
    height: auto;
    -webkit-animation: 3s ease 0s normal forwards 1 fadeIn;
      animation: 3s ease 0s normal forwards 1 fadeIn;
    
  }
  
  
  
  h1 {
    font-size: 2em;
  }
 
  p {
    font-size: 1.25em;
  }

  h2 {
    font-size: 1em;
  }

  .arrow {
    width: 20%;
  }

  .click {
    width: 30%;
  }

  .under-center {
    width: 100%;
    margin: 0;
    position: absolute;
    top: 75%;
    left: 50%;
    transform: translate(-50%, 0%);
    z-index: 999;
  }

  .rest-of-content {
    width: 100%;
    margin: 0;
    position: absolute;
    top: 105%;
    left: 50%;
    transform: translate(-50%, 0%);
  }

  .twitterFeed {
    width: 70%;
  }

  

  


}
/*
@media only screen and (min-width: 600px) and (max-width: 1000px)
{
  .specialDiv {
    width: 100%;
  }

  .oneLine {
    font-size: 1em;
  }
  

  .pageContainer {
    width: 100%;
    padding: 0;
    margin: 0;
    max-width: 100%;
    margin: 0 auto;
    min-height: 100vh;
    height: auto;
    -webkit-animation: 3s ease 0s normal forwards 1 fadeIn;
      animation: 3s ease 0s normal forwards 1 fadeIn;
    
  }
  
  .logo {
    width: 70%;
  }
  
  h1 {
    font-size: 1.5em;
  }
 
  p {
    font-size: 1.25em;
  }

  h2 {
    font-size: 1em;
  }

  .arrow {
    width: 10%;
  }

  .click { 
    width: 30%;
  }

  .under-center {
    width: 100%;
    margin: 0;
    position: absolute;
    top: 85%;
    left: 50%;
    transform: translate(-50%, -0%);
  }

  .twitterFeed {
    width: 50%;
  }

  .memsDoingThings {
    width: 50%;
    position: absolute;
    /* left: 50%; 
    transform: translate(-50%, -10%);
  }
  
  .footerLogo {
    width: 50%;
    position: absolute;
    transform: translate(-50%, 90%);

  }

  

}
*/
